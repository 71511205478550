@import 'src/scss/globals';

.dashboard-table {
  color: var(--colorNeutralForeground1);
  background-color: var(--colorNeutralBackground1);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: $gap-medium;
  padding: $gap-medium;

  &__details {
    display: flex;
    gap: 8px;
  }

  &__header-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: $gap-medium;
    margin-bottom: 36px;
    @include mobile {
      flex-direction: column;
    }
  }

  h1#{&}__header {
    flex-grow: 1;
    font-weight: normal;
    margin: 0 !important;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__header-icon {
    svg {
      width: 24px;
    }
  }

  &__header-filters-wrapper {
    display: flex;
    align-items: center;
  }

  &__search-input {
    width: 330px;
    @include mobile {
      width: 100%;
    }
  }

  &__table-container {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    width: 100%;
    max-width: calc(100vw - (55px) * 2);
  }

  .fui-TableCellLayout__content {
    max-width: 100%;
  }

  &__table {
    min-width: 620px;
    .ui-table__row {
      height: unset;
      padding: $gap-small 0;
    }

    .ui-table__header {
      padding-top: 0;
    }

    &-sortable-column-header {
      cursor: pointer;
    }

    &-Name-cell > div {
      display: flex;
      align-items: center;
      gap: $gap-smaller;      
      svg {
        flex-shrink: 0;
      }
    }

    &-Details-header {
      justify-content: flex-start;
      text-align: center;
    }
  }

  &__status {
    &--pending {
      color: var(--colorPaletteDarkOrangeForeground1);
    }
    &--signed {
      color: var(--colorPaletteLightGreenForeground2);
    }
    &--unsigned {
      color: var(--colorPaletteRedForeground1);
    }
    &--rejected {
      color: var(--colorPaletteRedForeground1);
    }
  }

  &__details-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: var(--colorCompoundBrandForeground1);
    &:hover {
      text-decoration: underline;
    }
  }

  &__sorting-icon.ui-icon {
    display: inline-flex;
    align-items: center;
  }

  &__no-documents-in-history {
    font-size: 16px;
    color: var(--colorNeutralForeground4);
    text-align: center;
  }
}

.dashboard-table__name-container {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  max-width: 100%;

  .fui-Text {
    @include ellipsis;
  }
}

.dashboard-table__name-icon {
  svg {
    width: 21px;
  }
}

.dashboard-table__process-details-dialog[role='dialog'] {
  // Increased specifiticty to override fluent styles
  width: 1200px;
  max-width: 96vw;
  padding: 0;

  & > .ui-dialog__content {
    margin-bottom: 0;
  }
}
