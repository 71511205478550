.file-browser-sidebar__container h2 {
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 1.5rem;

  &:not(:first-child) {
    margin-top: 15px;
  }
}

.file-browser-sidebar__container ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

button.file-browser-sidebar__menu-item {
  border: 0;
  border-radius: 0;
  height: initial;
  justify-content: initial;
  padding: 3px 15px 3px 30px;
  background: transparent;
  width: 100%;

  .ui-button__content {
    font-weight: lighter;
  }

  &,
  &:hover,
  &:focus-visible,
  &:active {
    border-color: transparent;
  }

  &--active {
    background-color: var(--colorBrandBackground);
    color: var(--colorNeutralForegroundStaticInverted);

    &:hover {
      background-color: var(--colorBrandBackgroundHover);
      color: var(--colorNeutralForegroundStaticInverted);
    }

    &:focus-visible {
      background-color: var(--colorBrandBackgroundHover);
      color: var(--colorNeutralForegroundStaticInverted);
    }

    &:active {
      background-color: var(--colorBrandBackground);
      color: var(--colorNeutralForegroundStaticInverted);
    }
  }

  &--large {
    padding: 12px 15px;

    .ui-button__content {
      font-size: 1rem;
      line-height: 1.25;
      font-weight: normal;
    }
  }
  &--normal {
    font-weight: normal;
  }
}
