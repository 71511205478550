@import 'src/scss/globals';

.file-signing {
  background-color: var(--colorNeutralBackground1);

  h2,
  h3 {
    margin: 0;
  }

  &__loader {
    background-color: var(--colorNeutralBackground1);
    justify-content: center;
  }

  &__input {
    span {
      width: 100%;
    }
  }

  &__recipient {
    margin-top: 12px;
  }

  &__choose-files-inner {
    display: flex;
    flex-direction: column;
    gap: $gap-medium;
  }

  &__two-column-view {
    display: grid;
    grid-template-columns: auto 280px;
    gap: $gap-large;

    @include mobile {
      grid-template-columns: auto;
    }
  }

  &-error-screen {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $gap-large;
    color: var(--colorPaletteRedBackground3);
    background-color: var(--colorPaletteBeigeBackground2);

    &__button {
      margin-top: $gap-small;
    }
  }

  &-success-screen {
    display: flex;
    flex-direction: column;
    gap: $gap-large;
    min-height: 100%;
    justify-content: center;
    padding: calc($gap-medium * 5);
    background: var(--colorNeutralBackground1);

    &__header {
      display: flex;
      flex-direction: row;
      gap: $gap-medium;
      align-items: center;
      color: var(--colorScheme-colorBrandForeground1);
      h1 {
        margin: 0 !important;
      }
    }

    &__text {
      overflow-wrap: break-word;
    }
  }

  &__column-with-gaps {
    display: flex;
    flex-direction: column;
    gap: $gap-smaller;
    padding-bottom: 6px;
  }

  &__step-buttons {
    margin-top: auto;
  }
}
