.file-browser-files-list__list {
  .ui-list__item {
    min-height: unset;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.menu-item-container {
  display: flex;
  gap: 10px;
}
