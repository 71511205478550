@import 'src/scss/globals';

.merge-convert-state-dialog {
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: $gap-large;
  }

  &__outcome-name {
    display: flex;
    flex-direction: column;
    gap: $gap-smaller;
    width: 350px;
  }

  &__outcome-label {
    opacity: 0.7;
  }

  &__merging {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__merge-success,
  &__merge-error {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    &-icon {
      height: 72px;
      width: auto;
    }
  }

  &__preview {
    @include text-type(400, 12px, 16px);
  }

  &__header {
    @include text-type(700, 18px, 24px);
  }
}
