.start-signing-task {
  height: 100%;

  .meeting-sign-intro-final-step {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    button {
      position: fixed;
      bottom: 33px;
      right: 33px;
    }
  }
}
