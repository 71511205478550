@import '~/src/scss/globals';

.tab-config {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $gap-large;
  height: 100%;

  &__icon {
    color: var(--colorScheme-colorBrandForeground1);
  }
}
