@import 'src/scss/globals';

.people-order {
  &__draggable {
    display: flex;
    align-items: center;
    gap: $gap-small;
    border-radius: 4px;
    border: 1px solid var(--colorScheme-colorNeutralStroke1);
    padding: $gap-small;
    margin-left: $gap-smaller;
  }
}
