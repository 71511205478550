@import '~/src/scss/globals';

.consents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $gap-large;
  height: 100%;
  background: var(--colorNeutralBackground1);

  &__header {
    max-width: 500px;
    text-align: center;
    white-space: pre-line;
  }

  &__icon {
    height: 60px;
    margin-bottom: 10px;
    color: var(--colorScheme-colorBrandForeground1);
  }
}
