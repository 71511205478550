@import '~/src/scss/globals';

.license-error {
  display: grid;
  grid-template-areas:
    'header'
    'subscription'
    'freemium';
  gap: $gap-large;
  max-width: 1300px;
  height: max-content;

  @media screen and (min-width: 1024px) {
    grid-template-areas:
    'header header'
    'subscription freemium';
    max-height: 660px;
  }

  &__page {
    padding: 15px 80px 30px 80px;
    display: flex;
    height: 100%;
    width: 100%;
    background: var(--colorNeutralBackground1);
    overflow: auto;

    @media screen and (min-width: 1024px) {
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  }

  &__icon {
    height: 60px;
    margin-bottom: 10px;
    color: var(--colorScheme-colorBrandForeground1);
  }

  &__header {
    text-align: center;
    white-space: pre-line;
    grid-area: header;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .fui-Text {
      text-align: center;
    }
  }

  .license-error__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }

  .license-feature {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    &__tag {
      border-radius: 3px;
      padding: 0px 8px;
      height: max-content;
    }
  }
  
  .license-box {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 30px;
    border-radius: 4px;
    
    @media screen and (min-width: 1024px) {
      display: grid;
      gap: 10px;
      grid-template-rows: 70px auto 60px 140px 30px
    }

    &__subscription {
      color:  var(--colorNeutralStrokeOnBrand2);;
      grid-area: subscription;
      background: linear-gradient(99deg, #4D49E1 56.33%, #9747FF 110.99%);

      .license-feature__tag {
        background:  var(--colorNeutralStrokeOnBrand2);;
        color: #4D49E1;
      }
    }
    
    &__freemium {
      grid-area: freemium;
      background: #F1F2FF;
      color: #4D49E1;

      .license-feature__tag {
        background: #4D49E1;
        color: var(--colorNeutralStrokeOnBrand2);
      }
    }

    &__icon {
      height: 48px;
      width: auto;
    }

    .fui-Button {
      max-width: max-content;
      color: inherit;
      border-color: inherit;
    }

    &.licence-box__disabled {
      opacity: 0.5;
    }
  }
}
