@import 'src/scss/globals';

.send-for-signing {
  background-color: var(--colorNeutralBackground1);
  max-height: 920px;

  &.wizard-box {
    & > div:not(:first-child):not(:last-child) {
      .send-for-signing__two-column-view {
        max-width: 80%;
      }
    }
  }

  h2,
  h3 {
    margin: 0;
  }

  &__info {
    gap: $gap-smaller;
    display: grid;
    grid-template-columns: min-content auto;
    align-content: start;

    &-icon {
      margin-top: 3px;
    }
  }

  &__two-column-view {
    display: grid;
    gap: $gap-large;
    grid-template-columns: auto;
    width: 100%;
    overflow-x: clip;

    &-left-column {
      display: flex;
      flex-direction: column;
      gap: $gap-medium;
    }

    &-right-column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__control-buttons {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }

  &__control-action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: $gap-medium;
  }

  &-error-screen {
    display: flex;
    flex-direction: column;
    gap: $gap-large;
    min-height: 100%;
    padding: $gap-large;

    color: var(--colorPaletteRedForeground1);
    background-color: var(--colorPaletteRedBackground1);

    button.ui-button {
      margin-top: $gap-medium;
    }
  }

  &-success-screen {
    display: flex;
    flex-direction: column;
    gap: $gap-large;
    min-height: 100%;
    justify-content: center;
    padding: calc($gap-medium * 5);

    background-color: var(--colorNeutralBackground1);

    &__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $gap-medium;
      & {
        color: var(--colorScheme-colorBrandForeground1);
      }
    }

    Button {
      max-width: 200px;
    }
  }

  &__column-with-gaps {
    display: flex;
    flex-direction: column;
    gap: $gap-medium;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
  }

  &__selected-files {
    display: flex;
    gap: $gap-small;
    align-items: center;
  }

  &__recipients-selection {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    line-height: 20px;

    button.ui-button {
      margin-left: auto;
    }
  }

  &__file-checks {
    display: flex;
    flex-direction: column;
    gap: $gap-small;
    margin-top: $gap-small;
  }
}
