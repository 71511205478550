.cc-recipient-rows {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 12px;

  &__inputs {
    display: flex;
    gap: 12px;

    & > * {
      width: 100%;
    }

    .ui-dropdown {
      button {
        height: 38px;
      }
    }
  }
}

.cc-recipients__action-button.fui-Button {
  color: var(--customColors-inperly);
}
