@import 'src/scss/globals';

.file-representation {
  &__file {
    background-color: var(--colorScheme-colorNeutralBackground3);
    padding: $gap-smaller;
    border-radius: 4px;

    &--error {
      border: 1px solid var(--colorScheme-colorPaletteRedBackground3);
    }
  }

  &__error {
    margin-top: $gap-smaller;
  }

  &__columns {
    display: flex;
    gap: $gap-small;
  }

  &__left-column {
    flex: 150px 0 1;
    display: flex;
    justify-content: flex-end;
    gap: $gap-small;

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 60px;
    }
  }

  &__center-column {
    flex: 1 1;

    &-content {
      display: flex;
      flex-direction: column;
      gap: $gap-smaller;
    }
  }

  &__right-column {
    flex: 0 1;
    display: flex;
    align-items: center;
  }

  &__preview {
    width: fit-content;
    @include text-type(400, 12px, 16px);
  }

  &__file-size {
    @include text-type(400, 12px, 16px);
    color: var(--colorNeutralForeground4);
  }

  &__order {
    display: flex;
    align-items: center;
    gap: $gap-small;
    color: var(--colorScheme-colorBrandForeground1);
  }

  &__selection {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  &__filename {
    height: 32px;
    display: flex;
    align-items: center;
    position: relative;
  }

  &__filename-text {
    position: absolute;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
