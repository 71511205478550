.filters-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.dashboard-filters {
  width: 100%;
  z-index: 1000;
  position: fixed;
  left: 68%;
  max-width: 300px;
  background-color: var(--colorNeutralBackground1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22);
  border-radius: 2px 2px 0px 0px;

  @media (max-width: 1440px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .dashboard-filters__close-icon {
    padding: 0;
    margin: 0;
    color: var(--colorNeutralForeground1Static);
  }

  .dashboard-filters-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--colorNeutralBackground1Pressed);
    padding: 16px;
    padding-bottom: 12px;
  }

  .dashboard-filters-content {
    height: 376px;
    overflow-y: scroll;
    margin-right: 4px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px 12px 16px 16px;

    &__field-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &__datepicker {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 4px;

      .fui-Input {
        width: 100%;
      }

      .fui-Text {
        color: var(--colorNeutralStrokeAccessible);
      }
    }

    &__datepicker-action-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 8px;

      .fui-Button {
        min-width: auto;
        padding: 0;
        font-weight: normal;
      }
    }

    &__datepicker-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 0 12px;
    }

    &__checkbox {
      display: flex;
      justify-content: space-between;
    }

    &__options {
      display: flex;
      flex-direction: column;
      padding: 0 12px;
      gap: 2px;
    }

    &__field-wrapper-header {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &__buttons-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 16px;
    }
  }
  
  h1, h2 {
    margin: 0;
  }
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: var(--colorNeutralBackground3Pressed);
  border-radius: 4px;
}

::-webkit-scrollbar-corner {
  background: none;
}