.license-reload-window {
  &__header {
    overflow-wrap: break-word;
  }

  & > &__content {
    justify-content: center;
    align-items: center;
  }

  .fui-Text {
    text-align: center;
  }

  &__icon {
    width: 100px;
    height: 100px;
  }
}