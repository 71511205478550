@import 'src/scss/globals';

.verification-summary-table {
  display: grid;
  grid-template-columns: repeat(5, auto);
  align-items: center;

  & > div {
    padding: $gap-small;
  }

  &__header {
    color: var(--colorScheme-colorNeutralForeground1);
    background-color: var(--colorScheme-colorNeutralBackground5);
    display: inline-flex;
    align-items: center;
    gap: $gap-smaller;
    height: 100%;
  }

  &__full-row {
    grid-column: 1 / -1;
    border-top: 1px solid var(--colorScheme-colorNeutralBackground5);
    border-bottom: 2px solid var(--colorScheme-colorNeutralBackground5);
    &:last-child {
      border-bottom: none;
    }
  }

  &__name {
    display: inline-flex;
    align-items: center;
    gap: $gap-smaller;
  }

  &__status {
    display: inline-flex;
    align-items: center;
    gap: $gap-smaller;
    &--icon {
      display: contents;
    }
    &--success {
      color: var(--colorScheme-colorPaletteLightGreenForeground1);
    }
    &--failed {
      color: var(--colorScheme-colorPaletteRedForeground1);
    }
    &--pending {
      color: var(--colorScheme-colorPaletteYellowForeground1);
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: $gap-large $gap-medium;

    &-photo {
      img {
        height: 100%;
        max-height: 200px;
        max-width: 200px;
      }
    }
    &-data {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      gap: $gap-large;
    }
  }

  &__detail {
    display: flex;
    flex-direction: column;
  }

  &__popover-surface {
    max-width: 30vh;
    overflow-wrap: break-word;
  }
}

.verification-summary-table-ocr-comparison {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 800px;
  }

  &__description {
    color: var(--colorScheme-colorNeutralForeground3);
  }

  &__data-container {
    display: flex;
    padding-top: 20px;
    padding-bottom: 16px;
    & > div:nth-child(2) {
      align-items: flex-end;
      border-left: 1px solid var(--colorScheme-colorNeutralForeground1);
    }
  }
}

.verification-summary-table-ocr-comparison-data {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__header {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  &__input-container {
    display: flex;
    flex-direction: column;
    max-width: 230px;
  }
}
