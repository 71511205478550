@import 'src/scss/globals';

.reminder-form-dialog__trigger-button {
  width: 100%;
  height: 32px;
}

.reminder-form-dialog__dialog-logo {
  max-width: 148px;
  height: auto;
}

.reminder-form-dialog__icon {
  width: 100%;
  display: flex;
  justify-content: center;
}

.reminder-form-dialog__text {
  margin-top: 48px;
  margin-bottom: 32px;
  text-align: center;
}

.reminder-form-dialog__textarea {
  textarea {
    height: 96px;
    background: var(--colorNeutralBackground3);
  }

  margin-bottom: 32px;
}

.inperly-logo-slogan-color {
  &__text {
    [app-theme='dark'] & {
      fill: var(--colorNeutralStrokeOnBrand2);
    }
  }

  &__description {
    [app-theme='dark'] & {
      fill: var(--colorNeutralStrokeOnBrand2);
    }
  }
}

.reminder-form-dialog__dialog-button {
  height: 32px;
  width: 96px;
}
