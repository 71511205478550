.file-list__menu-icon {
  margin-right: 8px;
}

.file-list__table-cell-with-flex > .ui-table__cell__content {
  width: 100%;
}

.file-list__options-cell {
  text-align: right;
}


.file-list__folder-link {
  cursor: pointer;
  outline: none;
}

.file-list__folder-link:hover {
  text-decoration: underline;
}

.file-list__folder-link:focus-visible {
  text-decoration: underline;
}
