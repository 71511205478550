@import 'src/scss/globals';

.meeting-stage-welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    gap: $gap-large;
    max-width: 700px;
  }
}
