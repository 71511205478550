@import 'src/scss/globals';

.files-merge-convert {
  display: flex;
  flex-direction: column;
  gap: $gap-small;

  &__file-dropper {
    display: flex;
    flex-direction: column;
    gap: $gap-smaller;
    margin-bottom: 2px;
  }

  &__merge-header {
    display: flex;
    padding: 0 $gap-smaller;
    gap: $gap-small;
    min-height: 32px;
  }

  &__merge-header-select {
    flex: 150px 0 1;
  }

  &__merge-header-name {
    display: flex;
    gap: $gap-small;
    align-items: center;
  }

  &__merge-files {
    display: flex;
    flex-direction: column;
    gap: $gap-small;
  }
}

.files-merge-convert__no-merge {
  .files-merge-convert__header,
  .file-representation__selection {
    visibility: hidden;
  }
}

.files-merge-convert__no-move {
  .file-representation__order {
    visibility: hidden;
  }
}
