@import 'src/scss/globals';

.tile-container {
  display: grid;
  grid-template-columns: auto;
  grid-auto-rows: min-content;
  gap: $gap-medium;

  @include mobile {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 15px;
  }

  .tile-wrapper {
    display: flex;
    justify-content: center;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    color: var(--colorNeutralForeground1);
    height: 168px;
    background: var(--colorNeutralBackground1);

    .icon-tile {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 15px;
      margin: $gap-small;

      &__image-container {
        height: 40%;

        img {
          max-height: 100%;
          max-width: 100%;
        }
      }

      &__text {
        text-align: center;
      }
    }

    .info-tile {
      display: grid;
      grid-template-columns: min-content 8px auto;
      grid-template-rows: min-content;
      align-content: center;
      align-items: center;
      margin: $gap-small;
      gap: $gap-small $gap-smaller;

      --info-tile-accent-color: var(--colorNeutralForeground1);
      &__pending {
        --info-tile-accent-color: var(--colorPalettePeachBorderActive);
      }
      &__unsigned {
        --info-tile-accent-color: var(--colorPaletteRedForeground1);
      }

      &__color-bar {
        height: 40px;
        background-color: var(--info-tile-accent-color);
      }

      &__count {
        color: var(--info-tile-accent-color);
        @include text-type(600, 40px, 20px);
        text-align: right;
      }
    }
  }
}
