.freemium-banner {
  width: 100%;
  border-radius: 4px;
  background: var(--billennium-inperly-teams-gradient, linear-gradient(108deg, #4D49E1 0%, #7548F1 64.11%, #9747FF 100%));
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  color: var(--colorNeutralStrokeOnBrand2);

  .limit--item,
  .freemium-banner__limit {
    display: flex;
    gap: 16px;
  }

  .freemium-banner__footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .fui-Link {
      color: var(--colorNeutralStrokeOnBrand2);
      text-decoration: underline;
    }
  }

  .limit--item-usage {
    white-space: nowrap;
  }

  .fui-Divider {
    &::before,
    &::after {
      border-color: var(--colorNeutralStrokeOnBrand2);
    }
  }

  &__dashboard {
    border-radius: 4px 4px 0 0;
    .limit--item {
      align-items: baseline;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .fui-Divider {
      flex-grow: 0;
    }

    .freemium-banner__limit {
      justify-content: flex-start;
    }
  }

  &__meeting {
    .freemium-banner__limit {
      flex-direction: column;
    }

    .limit--item {
      align-items: center;
      justify-content: space-between;
    }

    .limit--item-label {
      text-align: right;
    }
  }
}