.fui-DialogSurface:has(.file-browser-wrapper) {
  height: 80vh;
  padding: 0px;
  overflow: hidden; // for border-radius: it cuts out corners of children
  border: none;
}

.file-browser-wrapper {
  $border: 1px solid var(--colorNeutralBackground4);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &__header {
    padding: 15px;

    .ui-header {
      margin: 0;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 15px;
  }

  &__middle {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 200px auto;
    border-top: $border;
    border-bottom: $border;
    overflow: hidden;
  }

  &__sidebar {
    overflow-y: auto;
  }

  &__main-content {
    overflow-y: auto;
    border-left: $border;
  }

  &__errors {
    .ui-alert {
      border-radius: 0;
      border-width: 0;
      border-bottom-width: 1px;
    }
  }
}
