@import 'src/scss/globals';

.meeting-stage-signing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: $gap-medium;
  height: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $gap-medium;

    svg {
      height: 25px;
      width: auto;
    }

    &-middle {
      flex-grow: 1;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: $gap-large;
  }
}
