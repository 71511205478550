@import 'src/scss/globals';

.recipient-rows {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 12px;

  &__dismiss {
    display: flex;
    align-self: flex-start;
    height: 32px;

    * {
      margin: auto 0;
    }
  }

  &__inputs {
    display: grid;
    grid-template-columns: 28% 28% auto 1fr;
    gap: 12px;

    & > * {
      width: 100%;
      height: fit-content;
      min-width: 0;
    }

    .ui-dropdown {
      button {
        height: 38px;
      }
    }
  }

  &__headers-container {
    grid-template-columns: 1fr auto;
    display: grid;
    grid-gap: 12px;
    width: 100%;
  }

  &__headers {
    width: 100%;
    display: grid;
    grid-template-columns: 28% 28% 1fr;
    gap: 12px;
    margin-bottom: $gap-smaller;
    color: var(--colorNeutralForeground4);

    & > * {
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 0;
      overflow: hidden;
    }
  }

  &__header {
    &--1 {
      flex: 1 1;
    }

    &--2 {
      flex: 2 1;
    }
  }

  &__button-space {
    width: 20px;
  }
}

.recipient__action-button.fui-Button {
  color: var(--customColors-inperly);
}