@import 'src/scss/globals';

.side-panel {
  display: flex;
  flex-direction: column;
  gap: 50px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  &__intro,
  &__verification-video {
    display: flex;
    flex-direction: column;
    gap: $gap-small;
  }

  &__verification-video {
    padding: 10px;
    border-radius: var(--borderRadiusMedium);
    border: 1px solid transparent;
    &--open {
      border-color: var(--colorScheme-colorNeutralStroke3);
    }
  }

  &__verification-video-checkbox {
    .fui-Checkbox__indicator {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;
    }

    label {
      padding: 0;
      user-select: none;
    }
  }

  &__section-title {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 24px;
  }

  &__dashboard {
    background-color: var(--colorScheme-colorNeutralBackground2);
    border: 1px solid var(--colorScheme-colorNeutralStroke1);
    max-width: 280px;
    display: flex;
    flex-direction: column;
    gap: $gap-medium;
    padding: $gap-medium;

    &-buttons-wrapper {
      display: flex;
      flex-direction: column;
      gap: $gap-smaller;
    }
  }
}
