@import 'src/scss/globals';

.sortable-recipient-rows {
  display: grid;
  grid-template-columns: 1fr auto;
  align-self: self-start;

  &__inputs {
    display: grid;
    grid-template-columns: 28% 28% auto 1fr;
    gap: 12px;

    & > * {
      width: 100%;
      height: fit-content;
      min-width: 0;
    }

    .ui-dropdown {
      button {
        height: 38px;
      }
    }
  }

  &__headers-container {
    grid-template-columns: 55px 1fr auto;
    display: grid;
    grid-gap: 12px;
    width: 100%;
  }

  &__headers {
    display: grid;
    grid-template-columns: 28% 28% 1fr;
    gap: 14px;
    width: 100%;
    margin-bottom: $gap-smaller;
    color: var(--colorNeutralForeground4);

    & > * {
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 0;
      overflow: hidden;
    }
  }

  &__header {
    &--1 {
      flex: 1 1;
    }

    &--2 {
      flex: 2 1;
    }
  }

  &__handle-space {
    min-width: 32px;
  }

  &__button-space {
    min-width: 38px;
  }
}

.sortable-recipients__action-button.fui-Button {
    color: var(--customColors-inperly);
}

.sortable-rows-container {
  list-style: none;
  padding: 0;
  margin: 0 0 12px 0;

  li:not(:last-child) {
    margin-bottom: 12px;
  }

  .sortable-flex-space {
    display: flex;
    gap: 8px;
    align-items: center;
    color: var(--customColors-inperly)
  } 
}

.draggable-inputs {
  &__list {
    display: grid;
    grid-template-columns: 55px 1fr auto;
    align-items: baseline;
    grid-gap: 12px;

    &--hash {
      font-weight: bold;
    }

    &--container,
    &--dismiss {
      display: flex;
      align-self: flex-start;
      height: 32px;

      * {
        margin: auto 0;
      }
    }
  }
}
