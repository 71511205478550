@import 'src/scss/globals';

.dashboard-wrapper {
  display: grid;
  width: 100%;
  height: 100%;
  justify-items: center;

  & > * {
    grid-area: 1 / 1 / -1 / -1;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .dashboard-background-color-1 {
    background: var(--customColors-dashboardBackground);
  }

  .dashboard-background-image {
    background: url('background_dashboard.svg');
    background-repeat: no-repeat;
    background-position: top center;

    [app-theme="dark"] & {
      filter: grayscale(1) opacity(0.1);
    }
  }

  .dashboard-background-color-2 {
    padding-top: 208px;
    & > div {
      background: var(--colorNeutralBackground2);
      height: 100%;
    }
  }

}

.dashboard-content-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  max-height: 100vh;

  .dashboard-header {
    display: flex;
    gap: $gap-medium;
    margin: $gap-large;
    margin-bottom: 40px;

    &__logo {
      height: 50px;
      width: auto;
      color: var(--colorNeutralStrokeOnBrand2);
    }
  }

  .dashboard-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin: 0 30px 30px 30px;
    min-height: 300px;
    
    @include mobile {
      margin: 0;
      min-height: 250px;
    }

    & > .wizard-box {
      padding: 0 15px;
      @include mobile {
        padding: 0;
      }
    }
  }

  .dashboard-content-columns {
    display: grid;
    grid-template-columns: 250px auto;
    gap: $gap-medium;

    @include mobile {
      grid-template-columns: auto;
    }
  }
}
