.layered-container {
  display: grid;
  height: 100%;
  overflow: auto;

  & > * {
    grid-area: 1 / 1 / -1/ -1;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
}
