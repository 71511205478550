@import 'src/scss/globals';

.stage-verification {
  display: flex;
  flex-direction: column;
  gap: $gap-medium;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $gap-medium;

    &-logo {
      height: 25px;
      width: auto;
    }

    &-middle {
      flex-grow: 1;
    }
  }
}
