@import 'src/scss/globals';

.sign-task-container {
  display: grid;
  height: 100%;
  
  & > * {
    grid-area: 1 / 1 / -1/ -1;
    height: 100%;
    width: 100%;
  }
}

.sign-task-cancel-confirmation-screen {
  --wizard-box-padding: 30px;
  z-index: 1000;
}

.prompt-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  &-message {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.sign-window {
  &__header-with-filename {
    overflow-wrap: break-word;
  }

  &__available-providers {
    font-size: 14px;
    opacity: 0.7;
    font-weight: 400;
  }

  &__country-selection-label {
    opacity: 0.7;
    font-size: 12px;
  }

  &__providers-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
