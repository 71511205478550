.tab-breadcrumbs {
  display: flex;
  align-items: center;
  padding: 12px 0;

  .ui-breadcrumb__link {
    color: var(--colorNeutralForeground1);
  }

  button {
    margin-left: auto;
  }
}
