@import 'src/scss/globals';

.process-details {
  padding: 16px 30px;
  position: relative;

  &__participant-table {
    th {
      text-align: left;
      font-weight: 400;
      font-size: 12px;
      color: var(--colorScheme-colorNeutralForeground4);
    }
  }

  &__data {
    color: var(--colorScheme-colorNeutralForeground4);
    font-weight: 400;
    font-size: 14px;
  }

  &__date {
    color: var(--colorScheme-colorNeutralForeground3);
  }

  &__recipient {
    color: var(--colorScheme-colorNeutralForeground1);
  }

  &__adjusted {
    min-width: max-content;
  }

  &__participant-rows {
    display: flex;
    flex-direction: column;
    gap: $gap-medium;
  }

  &__header-container {
    display: flex;
    gap: $gap-medium;
  }

  &__header {
    margin: 0;
    word-break: break-word;
  }

  &__header-icon {
    flex: 0 0 auto;
    justify-content: center;
    margin: 14px 0;
  }

  &__header-subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &__header-close {
    margin: 12px 0 12px auto;
  }

  &__header-status {
    margin: 10px 0;
    font-weight: 600;

    &--waiting {
      color: var(--colorScheme-colorPaletteDarkOrangeForeground1);
    }

    &--cancelled {
      color: var(--colorScheme-colorNeutralForeground1);
    }

    &--signed {
      color: var(--colorScheme-colorPaletteLightGreenForeground3);
    }

    &--rejected {
      color: var(--colorScheme-colorPaletteRedForeground3);
    }

    &--archived {
      color: var(--colorScheme-colorNeutralForeground1);
    }
  }

  &__signature {
    .process-details__cancel-request-button {
      color: var(--colorScheme-colorPaletteRedForeground3);
      border-color: var(--colorScheme-colorPaletteRedBorderActive);
      margin-left: $gap-small;

      &:hover {
        border-color: var(--colorScheme-colorPaletteRedBorderActive);
        background-color: var(--colorScheme-colorPaletteRedBackground3);
        color: var(--colorScheme-colorNeutralForegroundOnBrand);
      }
    }
  }

  &__section-title {
    margin-bottom: 8px;
  }

  &__time-frame-content {
    display: flex;
    gap: $gap-small 100px;

    @include mobile {
      flex-wrap: wrap;
    }
  }

  &__time-frame-item {
    display: flex;
    gap: $gap-small;
    align-items: center;
    color: var(--colorScheme-colorBrandForeground1);
  }

  &__files-content {
    display: flex;
    align-items: center;
    gap: $gap-small;
  }

  &__footer {
    display: flex;
    gap: $gap-small;
    justify-content: flex-end;
  }

  &__restore-icon {
    transform: scaleX(-1);
  }

  &__participants-content {
    display: flex;
    flex-direction: column;
    gap: $gap-small;
  }

  &__participants-container {
    display: flex;
  }

  &__participants-wrapper {
    flex: 0 1 800px;
  }

  &__participant-info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1px, auto));
    gap: 0 $gap-small;
    align-items: center;

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  &__participant-row {
    display: flex;
    flex-direction: column;
  }

  &__participant-user {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__participant-status {
    font-weight: 600;
    text-align: left;
    display: block;
    width: 100%;
    padding-right: 12px;

    &--waiting {
      color: var(--colorPaletteDarkOrangeForeground1);
    }

    &--cancelled {
      color: var(--colorPaletteRedForeground1);
    }

    &--signed {
      color: var(--colorPaletteLightGreenForeground2);
    }

    &--rejected {
      color: var(--colorPaletteRedForeground1);
    }
  }

  &__rejection-reason {
    opacity: 0.7;

    &:empty {
      display: none;
    }
  }

  &__loader-container {
    position: absolute;
    inset: 0;
    background-color: var(--colorScheme-colorNeutralBackground1);
    z-index: 1000; // Covering content instead of hiding it to preserve the size (changing visibility is too slow)
  }

  &__loader {
    position: absolute;
    inset: 0;
  }

  &__participant-photo {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 32px;
      height: 32px;
    }
  }
}

/**For dialog body width */
.process-details-dialog {
  width: fit-content;
  max-width: 1200px !important;
}
