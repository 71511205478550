@import 'src/scss/globals';

.file-dropper {
  cursor: pointer;
  padding: 15px;
  border: 1px solid;
  color: var(--colorScheme-colorNeutralForeground4);
  border-color: var(--colorScheme-colorNeutralStroke1);
  border-radius: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: $gap-small;
  align-items: center;
}
