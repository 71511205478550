@import 'src/scss/globals';

.calendar-details {
  display: flex;
  flex-direction: column;
  height: 100%;

  padding: $gap-large;

  &__verfications-not-found {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: $gap-medium;
  }

  &__grey-text {
    color: var(--colorScheme-colorNeutralForeground4);
  }
}
