.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.alert-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 600px;
  gap: 32px;
  z-index: 1000;
  position: fixed;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22);
  border-radius: 2px 2px 0px 0px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--colorNeutralBackground1);
}

.alert-success {
  display: block;
}

.alert-error {
  &__header {
    display: flex;
    justify-content: space-between;

    &__logo {
      height: 50px;
      width: auto;
    }
  }
  &__content {
    background-color: var(--colorPaletteRedBackground1);
    width: 600px;
    justify-content: center;
    height: 258px;
  }
}
