@import 'src/scss/globals';

.side-panel-status-counters {
  display: grid;
  grid-template-columns: auto min-content;
  align-items: center;
  gap: $gap-small 0;

  &__name,
  &__value {
    padding: $gap-smaller;
    background-color: var(--colorScheme-colorNeutralBackground3);
    color: var(--colorScheme-colorNeutralForeground1);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__name {
    padding-left: $gap-medium;
    overflow-wrap: anywhere;
  }

  &__value {
    padding-right: $gap-medium;
    font-weight: bold;
    font-size: 30px;
    height: 50px;
  }
}
