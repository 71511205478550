.file-browser-files {
  &__container {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
  }

  &__scrollable {
    flex: 1;
    flex-basis: 0; // it must be provided separately from "flex", otherwise it's somehow overwritten
    overflow: auto;
    padding-top: 24px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: min-content;
  }

  &__no-content {
    color: var(--colorNeutralForeground2);
  }
}
