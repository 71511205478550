@import 'src/scss/globals';

.process-details-body__container {
  max-height: 500px;
  width: 1000px;
  overflow: scroll;
}

.process-details__pill {
  pointer-events: none;
}

.process-details-body__buttons {
  display: flex;
  width: 100%;
  justify-content: end;
}

.process-details-body__header {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  width: 100%;

  &-subtitle {
    display: flex;
    flex-direction: column;
    max-width: 70%;

    .fui-Text {
      @include ellipsis;
    }
  }

  &-pill {
    &--waiting {
      color: var(--colorPaletteDarkOrangeForeground1);
    }

    &--signed {
      color: var(--colorPaletteLightGreenForeground2);
    }

    &--rejected {
      color: var(--colorPaletteRedForeground1);
    }
  }
}

.process-details-body__sign-type {
  display: flex;
  margin-top: 24px;
  gap: 8px;

  &-cancel {
    border-color: var(--colorPaletteRedBackground3) !important;

    & > * {
      color: var(--colorPaletteRedBackground3);
    }
  }

  &-cancel:hover {
    background: var(--colorPaletteRedBackground3) !important;

    & > * {
      color: var(--colorNeutralStrokeOnBrand2Hover);
    }
  }
}

.process-details-body__spacing {
  margin-top: 8px;
}

.process-details-body__time-container {
  display: inline-flex;
  align-items: center;
  gap: 200px;
}

.process-details-body__time {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.process-details-body__primary {
  color: var(--colorBrandForegroundLink) !important;
}

.process-details-body__file {
  display: flex;
  align-items: center;
  gap: 12px;
}

.process-details-body__file-name.fui-Text {
  max-width: 20vw;
  @include ellipsis;
}
